import React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => (
    <>
        <h1>Oops ! PAGE NOT FOUND <Link to="/">Go back to the homepage</Link></h1>
    </>
)

export default NotFoundPage
